.load-hobbyzhub-container{
  height: 100vh;
}

.load-hobbyzhub-image-wrapper{
  width: 100px;
  height: fit-content;
}
.load-hobbyzhub-image-wrapper img{
  width: 100%;
  height: 100%;
  fill: var(--secondary-logo-color);
}