#root{
    --primary-bg: #fafafa;
    --secondary-bg: #ffffff;
    --border-color: #ededed;
    --primary-input-bg: #ededed;
    --primary-btn-bg: #26A4FF;
    --primary-logo-color: #26A4FF;
    --secondary-logo-color: #8e8e8e;
    --primary-text: #262626;
    --secondary-text: #8e8e8e;
  }