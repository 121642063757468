@import './style/variables.css';

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@font-face {
  font-family: 'hobbyzhub-fonts';
  src:  url('../public/myFonts/myFont.woff2') format('woff2'),
        url('../public/myFonts/myFont.woff2') format('woff');
}


body{
  background-color: #fafafa;
  scroll-behavior: smooth;
}

::-webkit-scrollbar{
  height: 0px;
}

/* common classes */

.absolute-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.align-center{
  display: flex;
  align-items: center;
}
.cur-point{
  cursor: pointer;
}
.hide-content{
  display: none;
}
.errorMessage{
  color: red;
}
.top-hobbyzhub-logo {
  display: none;
}
.hobbyzhub-logo{
  font-size: 40px;
  font-family: 'hobbyzhub-fonts';
  height: 50px;
}
.navbar-hobbyzhub-logo{
  font-size: 30px;
  font-family: 'hobbyzhub-fonts';
  height: 70px;
}

 /* responsive code for mobile */
@media (max-width:426px) {
  .top-hobbyzhub-logo {
    display: flex;
    height: fit-content;
    align-items: center;
    background: var(--secondary-bg);
    border-bottom: 1px solid var(--border-color);
    padding: 5px 0 0px 5px;
  }

  
}